import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile, tablet } from "clutch/src/Style/style.mjs";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { APP_SCROLLER, appURLs } from "@/app/constants.mjs";
import { TRAIT_BLACKLIST } from "@/game-tft/constants.mjs";
// import ParsedDescription from "@/game-tft/ParsedDescription.jsx";
import Augments from "@/game-tft/UpdatesSetTenAugments.jsx";
import Champions from "@/game-tft/UpdatesSetTenChampions.jsx";
// import Items from "@/game-tft/UpdatesSetTenItems.jsx";
import Traits from "@/game-tft/UpdatesSetTenTraits.jsx";
import HelpIcon from "@/inline-assets/help.svg";
import Container from "@/shared/ContentContainer.jsx";
import { MatchLoading } from "@/shared/Match.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Banner from "@/shared/SplashBanner.jsx";
import globals from "@/util/global-whitelist.mjs";
// import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const tabs = [
  {
    key: "whatsnew",
    label: ["val:whatsNew", "What's new?"],
    Component: UpdatesWhatsNew,
  },
  {
    key: "traits",
    label: ["tft:common.traits", "Traits"],
    Component: UpdatesTraits,
  },
  {
    key: "champions",
    label: ["lol:champion_plural", "Champions"],
    Component: UpdatesChampions,
  },
  {
    key: "augments",
    label: ["tft:common:augments", "Augments"],
    Component: UpdatesAugments,
  },
  {
    key: "Cosmetics",
    label: ["common:navigation.cosmetics", "Cosmetics"],
    Component: UpdatesCosmetics,
  },
];
const tabsTraits = [
  { key: "origins", label: ["tft:traits:origins", "Origins"] },
  { key: "classes", label: ["tft:traits:classes", "Classes"] },
];
const tabsChampions = [
  { key: "all", label: ["common:all", "All"] },
  { key: "1", label: ["", "1"] },
  { key: "2", label: ["", "2"] },
  { key: "3", label: ["", "3"] },
  { key: "4", label: ["", "4"] },
];
const tabsAugments = [
  { key: "Tier 1", label: ["", "Silver"] },
  { key: "Tier 2", label: ["", "Gold"] },
  { key: "Tier 3", label: ["", "Prismatic"] },
];
const tabsCosmetics = [
  { key: "Tacticians", label: ["", "Tacticians"] },
  { key: "Arena", label: ["", "Arena"] },
];
const image = `${appURLs.CDN}/blitz/tft/set-update-page/Set14/Cyber-City.webp`;
const header = ["common:navigation.set14update", "Set 14 Update"];
const title = ["", "Set 14 Update - Cyber City"];
const subTitle = ["", "Teamfight Tactics - March 2024"];
const set = "set14";
const gap = 48; // Represents the var(--sp-12) computed container spacing
const debounce = { indexes: [], delay: 100 };

function isBottomOfContainer(target) {
  return target.scrollTop + target.clientHeight >= target.scrollHeight;
}

function Reveal({ id, timeout, children, headerOffset }) {
  const ref = useRef(null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setReady(true);
    }, timeout);
    return () => {
      clearTimeout(id);
    };
  }, [timeout]);

  useLayoutEffect(() => {
    const node = ref?.current;
    if (!node) return;

    const index = tabs.findIndex((i) => i.key === id);
    const root = globals.document.getElementsByClassName(APP_SCROLLER)[0];
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        // Intersect
        if (isIntersecting) {
          if (!debounce.indexes.some((i) => i === index)) {
            debounce.indexes.push(index);
          }
        } else {
          const indexOfIndex = debounce.indexes.findIndex((i) => i === index);
          if (index === tabs.length - 1 && debounce.indexes.length === 1) {
            // Handles the scroll event in Updates Component
            debounce.indexes.push(tabs.length - 2);
          }
          if (indexOfIndex >= 0) debounce.indexes.splice(indexOfIndex, 1);
        }
        // Handles multiple sections if we reached the bottom of the root
        if (isBottomOfContainer(root)) debounce.indexes = [tabs.length - 1];
        // Sort and navigate
        if (debounce.indexes.length > 1) debounce.indexes.sort();
      },
      {
        root,
        rootMargin: `-${headerOffset}px 0px 0px 0px`, // Only takes % or px values
      },
    );
    observer.observe(node);
    return () => {
      observer.disconnect();
    };
  }, [headerOffset, id]);
  return (
    <div id={id} ref={ref}>
      {isReady ? (
        children
      ) : (
        <LoadingContainer>
          <MatchLoading />
        </LoadingContainer>
      )}
    </div>
  );
}

function Updates() {
  const { t } = useTranslation();
  const [headerOffset, setHeaderOffset] = useState(0);

  const links = useMemo(() => {
    return tabs.map((i) => {
      return {
        text: t(...i.label),
        url: `/tft/set-${set.replace("set", "")}?section=${i.key}`,
        routeMatch: new RegExp(i.key, "i"),
        onClick(event) {
          event.preventDefault();
          const element = globals.document.getElementById(i.key);
          const main = globals.document.getElementsByClassName(APP_SCROLLER)[0];
          // Added 1px to offset IntersectionObservers intersecting range
          main.scrollTop = element.offsetTop - gap + 1;
          return false;
        },
      };
    });
  }, [t]);

  useEffect(() => {
    const view = globals.document.getElementsByClassName(APP_SCROLLER)?.[0];
    if (!view) return;
    function scroll(event) {
      const target = event.target;
      if (isBottomOfContainer(target)) {
        debounce.indexes = [tabs.length - 1];
      }
      // This header offset number is used for IntersectionObserver's rootMargin property to accomodate for the sticky header
      const attrTarget = "data-sticky-cumulative-height";
      const stickyCumulativeHeights = Array.from(
        globals.document.querySelectorAll(`[${attrTarget}]`),
      ).map((i) => Number(i.getAttribute(attrTarget)));
      stickyCumulativeHeights.sort((a, b) => b - a);
      setHeaderOffset(stickyCumulativeHeights[0] ?? 0);
    }
    view.addEventListener("scroll", scroll);
    return () => {
      view.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <>
      <PageHeader title={t(...header)} links={links} />
      <Container style={{ display: "grid", gap: "var(--sp-12)" }}>
        {tabs.map((tab, idx) => {
          const Component = tab.Component;
          return (
            <Reveal
              key={idx}
              id={tab.key}
              timeout={idx * 500}
              headerOffset={headerOffset}
            >
              {idx === 0 ? (
                <Banner
                  image={image}
                  imageWidth="350px"
                  imageHeight="350px"
                  imageAspectRatio="1/1"
                  title={t(...title)}
                  subtitle={t(...subTitle)}
                  title2={
                    /* eslint-disable */
                    <div className="flex column type-subtitle">
                      <div>PBE Release: Tuesday, Match 18th</div>
                      <div>Live Release: Wednesday, April 2nd</div>
                    </div>
                    /* eslint-enable */
                  }
                >
                  <Component />
                </Banner>
              ) : (
                <Component />
              )}
            </Reveal>
          );
        })}
      </Container>
    </>
  );
}

export function meta() {
  return {
    title: [
      "tft:helmet.updates.titleSet14",
      "TFT Set 14 - Cyber City: New champions, traits & more!",
    ],
    description: [
      "tft:helmet.updates.descriptionSet14",
      "Explore TFT Set 14 - Cyber City with new champions, traits & dominate the Convergence! ",
    ],
    image: {
      url: `${appURLs.CDN}/blitz/tft/set-update-page/Set14/Cyber-City.webp`,
      alt: [
        "tft:helmet.updates.titleSet14",
        "TFT Set 14 Cyber City Update Patch Notes – Blitz Teamfight Tactics",
      ],
      width: 400,
      height: 400,
    },
  };
}

export default Updates;

function UpdatesWhatsNew() {
  const { t } = useTranslation();
  /* eslint-disable */
  return (
    <>
      <Section className="whatsNew">
        <h2 className="type-h4 section-title">
          {t("tft:whatsNew", "What's New")}
          <IconWrapper
            data-tip={t(
              "tft:pageIsBasedOn",
              "Page is based on data Riot provided early. Page will automatically update once more info gets revealed.",
            )}
            data-place={"bottom"}
          >
            <HelpIcon />
          </IconWrapper>
        </h2>
        <ContentBlock padding="var(--sp-8)">
          <p className="content type-body1">
            Here is a sneak peek into the TFT Set 14 Cyber City update!
          </p>
          <br />
          <p className="content type-body1">
            Set 14 will feature the following:
          </p>
          <ul className="content type-body1 color-shade1">
            <li>New Units – including Kobuko</li>
            <li>New Mechanic – Hacks</li>
            <li>New Traits</li>
          </ul>
        </ContentBlock>
      </Section>
      <Section className="newMechanics">
        <h2 className="type-h4 section-title">New Set Mechanic: Hacks</h2>
        <ContentBlock className="container" padding="var(--sp-8)">
          <p className="type-body1">
            You will be "hacked" at random throughout the game, resulting in
            unique augment options, new rewards from loot orbs, and more. On
            average, this will happen three times a game.
          </p>
          <div className="flex">
            <img
              src={`${appURLs.CDN}/blitz/tft/set-update-page/Set14/take-1-or-2.webp`}
              alt="Charms"
              width="413px"
            />
            <div className="right">
              <h4 className="title type-h6">Augment Hacks</h4>
              <ul className="content type-body1 color-shade1">
                <li>
                  A single augment option is hacked and replaced with a special
                  augment from an exclusive pool.
                </li>
                <li>
                  The entire augment selection is hacked and you are presented
                  with the choice between one augment or two augments of lesser
                  value (i.e. one gold augment vs. two silvers).{" "}
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="flex">
            <img
              src={`${appURLs.CDN}/blitz/tft/set-update-page/Set14/anvils.webp`}
              alt="Charms"
              width="413px"
            />
            <div className="right">
              <h4 className="title type-h6">Loot Orb Hacks</h4>
              <p className="content type-body1 color-shade1">
                Orbs are hacked to contain special rewards, such as bonus player
                health or an augmented item component that changes into a new
                component every turn.
              </p>
            </div>
          </div>
        </ContentBlock>
      </Section>
    </>
  );
  /* eslint-enable */
}

function UpdatesTraits() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const [currentTab, setTab] = useState(tabsTraits[0].key);
  const traits = state.tft?.[set]?.traits;
  const filteredTraits =
    traits &&
    Object.fromEntries(
      Object.entries(traits).filter(
        ([, trait]) => !TRAIT_BLACKLIST[set]?.includes(trait.apiKey),
      ),
    );
  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:traits", "Traits")}
      </h2>
      <ButtonGroup>
        {tabsTraits.map(({ key, label }) => (
          <Button
            key={key}
            active={key === currentTab}
            onClick={() => setTab(key)}
          >
            {t(...label)}
          </Button>
        ))}
      </ButtonGroup>
      <Traits
        buttons={tabsTraits}
        traitType={currentTab}
        traits={filteredTraits}
        set={set}
        t={t}
      />
    </Section>
  );
}

function UpdatesChampions() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const [currentTab, setTab] = useState(tabsChampions[0].key);
  const champions = state.tft[set]?.champions;
  const traits = state.tft[set]?.traits;
  const filteredChampions =
    champions && Object.fromEntries(Object.entries(champions));
  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:champions", "Champions")}
      </h2>
      <ButtonGroup>
        {tabsChampions.map(({ key, label }) => (
          <Button
            key={key}
            active={key === currentTab}
            onClick={() => setTab(key)}
          >
            {t(...label)}
          </Button>
        ))}
      </ButtonGroup>
      <Champions
        champions={filteredChampions}
        traits={traits}
        set={set}
        cost={currentTab}
        buttons={tabsChampions}
        isSkinsEnabled={isSkinsEnabled}
      />
    </Section>
  );
}

// function UpdatesAnomaly() {
//   const { t } = useTranslation();
//   const state = useSnapshot(readState);
//   const [seeMore, setSeeMore] = useState(false);
//   const [searchInput, setSearchInput] = useState("");
//   const data = useMemo(() => state.tft[set]?.anomalies || {}, [state.tft]);
//   const anomalies = useMemo(() => {
//     let filtered = seeMore ? Object.keys(data) : Object.keys(data).slice(0, 6);
//     if (searchInput) {
//       filtered = Object.keys(data).filter(
//         (x) =>
//           data[x].name.toLowerCase().includes(searchInput.toLowerCase()) ||
//           data[x].description.toLowerCase().includes(searchInput.toLowerCase()),
//       );
//     }
//     return filtered;
//   }, [data, searchInput, seeMore]);

//   if (!anomalies) return null;

//   return (
//     <Section className="section">
//       <div className="title">
//         <h2 className="type-h4 section-title">
//           {t("tft:common:anomaly", "Anomaly")}
//         </h2>
//         <TextInput
//           placeholder={t("tft:searchAnomaly", "Search Anomaly")}
//           onValueChange={setSearchInput}
//           className="search"
//         />
//       </div>
//       <TableContainer>
//         {anomalies.length === 0 ? (
//           <TableContainer>
//             <div className="placeholder">
//               {t("common:noneFound", "None Found")}
//             </div>
//           </TableContainer>
//         ) : (
//           <>
//             {anomalies.map((x, idx) => {
//               const name = data[x].name;
//               const description = data[x].description;
//               return (
//                 <div className="inner" key={idx}>
//                   <div className="nameColumn">
//                     <img
//                       src={`${appURLs.CDN}/blitz/tft/anomaly.webp`}
//                       loading="lazy"
//                       alt="anomaly icon"
//                     />
//                     <div>
//                       <div className="name">{name}</div>
//                     </div>
//                   </div>
//                   <ParsedDescription
//                     className="effectColumn type-caption"
//                     text={description}
//                   />
//                 </div>
//               );
//             })}
//             {!seeMore ? (
//               <SeeMore
//                 onClick={() => setSeeMore(true)}
//                 className="type-caption"
//               >
//                 {t("common:seeMore", "See More")}
//               </SeeMore>
//             ) : (
//               <SeeMore
//                 onClick={() => setSeeMore(false)}
//                 className="type-caption"
//               >
//                 {t("common:seeLess", "See Less")}
//               </SeeMore>
//             )}
//           </>
//         )}
//       </TableContainer>
//     </Section>
//   );
// }

// const TableContainer = styled("div")`
//   display: flex;
//   flex-direction: column;
//   margin-top: var(--sp-4);
//   min-height: var(--sp-12_5);
//   border-radius: var(--br);
//   overflow: hidden;
//   .inner {
//     display: flex;
//     overflow: hidden;
//     background: var(--shade8);
//     align-items: center;
//     border-bottom: var(--sp-px) solid var(--shade6);
//     padding: var(--sp-6);
//     gap: var(--sp-6);

//     ${mobile} {
//       flex-direction: column;
//     }
//   }
//   .nameColumn {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     width: 25%;
//     gap: var(--sp-4);

//     .name {
//       font-size: var(--sp-4);
//       line-height: var(--sp-5);
//       margin-bottom: var(--sp-1);
//     }
//     img {
//       width: 50px;
//       height: 50px;
//       border-radius: var(--sp-1);
//     }

//     ${mobile} {
//       padding-left: 0;
//     }
//   }
//   .effectColumn {
//     color: var(--shade1);
//     width: 40%;
//   }
//   .placeholder {
//     position: absolute;
//     margin-left: auto;
//     margin-right: auto;
//     left: 0;
//     right: 0;
//     text-align: center;
//     color: var(--shade3);
//   }

//   ${mobile} {
//     .nameColumn,
//     .effectColumn {
//       width: 100%;
//     }
//   }
// `;

// function UpdatesItems() {
//   const { t } = useTranslation();
//   const state = useSnapshot(readState);
//   const [currentTab, setTab] = useState(tabsItems[0]?.key);
//   const locale = getLocale();
//   const items = state.tft[set]?.items;
//   const itemsLocalized = state.tft[set]?.localizedItems?.[locale];

//   const buttonsWithItems = useMemo(() => {
//     const result = tabsItems.reduce((acc, { key }) => {
//       acc[key] = [];
//       return acc;
//     }, Object.create(null));
//     if (items && !(items instanceof Error)) {
//       const sorted = Object.entries(items).sort(
//         (a, b) => b[1].buildsFrom?.length - a[1].buildsFrom?.length,
//       );
//       return sorted.reduce((acc, item) => {
//         // if (item[1].state === "new") acc[buttons[0].key].push(item);
//         // if (item[1].state === "change" || item[1].state === "rework") {
//         //   acc[buttons[0].key].push(item);
//         // }
//         if (item[1].trait) acc[tabsItems[0].key].push(item);
//         if (item[1].kind === "junkerking") acc[tabsItems[1].key].push(item);
//         if (item[1].kind === "chembaron") acc[tabsItems[2].key].push(item);
//         if (item[1].kind !== "basic") acc[tabsItems[3].key].push(item);
//         return acc;
//       }, result);
//     }
//     return result;
//   }, [items]);

//   return (
//     <Section className="section">
//       <h2 className="type-h4 section-title">
//         {t("tft:common:items", "Items")}
//       </h2>
//       <ButtonGroup>
//         {tabsItems.map(({ key, label }) =>
//           key === "reworked" ? null : (
//             <Button
//               key={key}
//               active={key === currentTab}
//               onClick={() => setTab(key)}
//             >
//               {t(...label)}
//             </Button>
//           ),
//         )}
//       </ButtonGroup>
//       <Items
//         buttonsWithItems={buttonsWithItems}
//         itemType={currentTab}
//         localizedItems={itemsLocalized}
//         set={set}
//       />
//     </Section>
//   );
// }

function UpdatesAugments() {
  const { t } = useTranslation();
  const [currentTab, setTab] = useState(tabsAugments[0].key);
  const [searchInput, setSearchInput] = useState("");

  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:augments", "Augments")}
      </h2>
      <Filters>
        <ButtonGroup>
          {tabsAugments.map(({ key, label }) => (
            <Button
              key={key}
              active={key === currentTab}
              onClick={() => setTab(key)}
            >
              {t(label)}
            </Button>
          ))}
        </ButtonGroup>
        <TextInput
          placeholder={t("tft:searchAugment", "Search Augment")}
          onValueChange={setSearchInput}
          className="searchAugments"
        />
      </Filters>
      <Augments set={set} tier={currentTab} searchInput={searchInput} />
    </Section>
  );
}

function UpdatesCosmetics() {
  const { t } = useTranslation();
  const [currentTab, setTab] = useState(tabsCosmetics[0].key);

  const tacticians = [
    {
      video: "Project-Vayne-Unbound.mp4",
      cover: "Project-Vayne-Unbound.webp",
      name: "Project Vayne Unbound",
    },
    {
      video: "Chibi-Forecast-Janna.mp4",
      cover: "Chibi-Forecast-Janna.webp",
      name: "Chibi Forecast Janna",
    },
  ];
  const arenas = [
    {
      video: "Arena-Project-Edgefall.mp4",
      cover: "Arena-Project-Edgefall.webp",
      name: "Arena Project Edgefall",
    },
  ];

  return (
    <Section className="section">
      <div className="title">
        <h2 className="type-h4 section-title">
          {t("tft:common:cosmetics", "Cosmetics")}
        </h2>
      </div>
      <ButtonGroup>
        {tabsCosmetics.map(({ key, label }) => (
          <Button
            key={key}
            active={key === currentTab}
            onClick={() => setTab(key)}
          >
            {t(...label)}
          </Button>
        ))}
      </ButtonGroup>
      {currentTab === "Tacticians" ? (
        <TacticiansContainer>
          {tacticians.map(({ video, cover, name }, i) => (
            <div
              className="flex column align-center w-full gap-2"
              key={name + i}
            >
              <video
                controls
                controlsList="nodownload noplaybackrate"
                poster={
                  cover &&
                  `${appURLs.CDN}/blitz/tft/set-update-page/Set14/${cover}`
                }
              >
                <source
                  src={`${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/Set14/${video}`}
                />
              </video>
              <div>{name}</div>
            </div>
          ))}
        </TacticiansContainer>
      ) : (
        <ArenasContainer>
          {arenas.map(({ video, cover, name }, i) => (
            <div
              className="flex column align-center w-full gap-2"
              key={name + i}
            >
              <video
                controls
                controlsList="nodownload noplaybackrate"
                poster={
                  cover &&
                  `${appURLs.CDN}/blitz/tft/set-update-page/Set14/${cover}`
                }
              >
                <source
                  src={`${appURLs.CDN_VIDEOS}/tft/SetUpdatePage/Set14/${video}`}
                />
              </video>
              <div>{name}</div>
            </div>
          ))}
        </ArenasContainer>
      )}
    </Section>
  );
}

const TacticiansContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--sp-10);
  align-items: center;
  background: var(--shade8);
  padding: var(--sp-10);
  border-radius: var(--br);

  img,
  video {
    width: 100%;
    border-radius: var(--br);
  }

  ${mobile} {
    grid-template-columns: 1fr;
    img,
    video {
      width: 100%;
    }
  }
`;
const ArenasContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--sp-7_5);
  background: var(--shade8);
  padding: var(--sp-6);
  border-radius: var(--br);

  img,
  video {
    width: 600px;
    border-radius: var(--br);
  }

  ${tablet} {
    img,
    video {
      width: 100%;
    }
  }
`;

const Filters = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: var(--sp-2);

  .searchAugments {
    width: 100%;
    max-width: 18ch;
  }
`;

//STYLES
const ContentBlock = styled(Card)`
  .content {
    max-width: 70ch;

    a {
      color: var(--primary);
    }
  }

  ul.content {
    margin-bottom: 1em;
    padding-left: var(--sp-8);
    list-style-type: initial;
  }

  img {
    max-width: 100%;
    filter: brightness(1.1);
  }

  video {
    border-radius: var(--br);
    &:hover {
      cursor: pointer;
    }
  }

  .vsc-controller {
    display: none;
  }

  .content-img {
    max-width: 85%;

    ${mobile} {
      max-width: 100%;
    }
  }

  .flex {
    display: flex;

    ${mobile} {
      flex-direction: column;
    }
  }

  .horizontal-tiles {
    display: flex;
    flex-direction: row;
    gap: var(--sp-10);

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: flex-end;
      gap: var(--sp-4);
    }

    hr {
      border: var(--sp-0_5) solid var(--shade6);
      border-right: none;
    }

    ${mobile} {
      flex-direction: column;

      hr {
        width: 100%;
      }
    }
  }
`;

const IconWrapper = styled("div")`
  display: inline-block;
  cursor: pointer;
  margin: 0 var(--sp-2);

  svg {
    width: var(--sp-6);
    height: var(--sp-6);
    fill: var(--shade3);
  }
`;

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);

  &.newMechanics {
    margin-top: var(--sp-12);

    .container {
      gap: var(--sp-6);
      display: flex;
      flex-direction: column;
    }

    .flex {
      justify-content: space-around;
      align-items: center;
    }

    .right {
      max-width: 330px;

      .title {
        margin-bottom: var(--sp-2);
      }
    }

    hr {
      border: var(--sp-px) solid var(--shade6);
      width: 100%;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchPortals {
      width: 100%;
      max-width: 18ch;
    }

    ${mobile} {
      flex-direction: column;
      align-items: flex-start;

      label {
        width: 100%;
        margin-top: var(--sp-4);
      }
    }
  }

  .leveling-changes {
    list-style: disc;
    padding-left: var(--sp-6);
  }

  ${mobile} {
    &.whatsNew {
      margin-top: 200px;
    }

    .button-group {
      flex-wrap: wrap;
    }
  }
`;

// Not the same one used in game-lol or game-tft, just shadowed the name
const LoadingContainer = styled("div", forwardRef)`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeMore = styled("div")`
  width: 100%;
  text-align: center;
  color: var(--shade1);
  background: var(--shade7);
  padding: var(--sp-2) 0;
  border-radius: 0 0 var(--br) var(--br);
  &:hover {
    color: var(--shade0);
    background: var(--shade6);
    cursor: pointer;
  }

  ${mobile} {
    width: 100%;
  }
`;
